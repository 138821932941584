<template>
	<div class="main-wrapper">
		<div class="d-flex justify-content-between ">
			<h4 class="mb-4">
				User info
			</h4>
			<div class="typo-body-2 color-black-45">
				<span>User group: </span>
				<strong>{{ userInfo.group }}</strong>
			</div>
		</div>
		<BaseLoading v-if="isLoading" is-fullpage />
		<CForm
			v-else
			class="main-wrapper"
			@submit.prevent="submit"
		>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.username.$model"
						:label="$t('page.users.create.formLabel.username')"
						:is-valid="isValidUsername"
						:invalid-feedback="getValidateUsernameMessage"
						disabled
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-if="isAllowChangePassword"
						v-model.trim="$v.password.$model"
						:label="$t('page.users.create.formLabel.password')"
						:is-valid="isValidPassword"
						:invalid-feedback="getValidatePasswordMessage"
						:description="getPasswordDescription"
						@input="clearError"
					/>
					<template v-else>
						<div class="form-group">
							<label>Password</label>
							<div>
								<CButton
									class="btn-change-password flex transparent btn-export-csv"
									color="tertiary"
									@click="handleAllowChangePassword"
								>
									Change password
								</CButton>
							</div>
						</div>
					</template>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="6">
					<CInput
						v-model.trim="$v.firstName.$model"
						:label="`${$t('page.users.create.formLabel.firstName')}*`"
						:is-valid="isValidFirstName"
						:invalid-feedback="getValidateFirstNameMessage"
						@input="clearError"
					/>
				</CCol>
				<CCol lg="6">
					<CInput
						v-model.trim="$v.lastName.$model"
						:label="$t('page.users.create.formLabel.lastName')"
						:is-valid="isValidLastName"
						:invalid-feedback="getValidateLastNameMessage"
						@input="clearError"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol
					class="wrapper-active-field mt-4 mb-5"
					lg="12"
				>
					<label class="switch-active-label">
						{{
							$t('page.users.create.formLabel.status')
						}}
					</label>
					<CSwitch
						:checked.sync="status"
						class="switch-custom swtich-active-status-button"
						name="status"
						variant="3d"
						size="sm"
						color="success"
						@update:checked="handleClickSwitch"
					/>
					<p class="label-active-status typo-body-2">
						{{ getStatusLabel }}
					</p>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.email.$model"
						:label="$t('page.users.create.formLabel.email')"
						:is-valid="isValidEmail"
						:invalid-feedback="getValidateEmailMessage"
						@input="clearError"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.phone.$model"
						:label="$t('page.users.create.formLabel.phone')"
						:is-valid="isValidPhone"
						:invalid-feedback="getValidatePhoneMessage"
						@input="clearError"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<BaseDropDown
						v-model="store"
						:options="warehouseOptions"
						label="name"
						label-drop-down="Store ID"
						placeholder="Select store"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center" class="mb-5">
				<CCol lg="12">
					<CTextarea
						v-model.trim="$v.note.$model"
						:label="$t('page.users.create.formLabel.note')"
						:is-valid="isValidNote"
						:invalid-feedback="getValidateNoteMessage"
						@input="clearError"
					/>
				</CCol>
			</CRow>

			<BaseActionPanelStickyFooter
				:disabled-confirm="isUpdating"
				content-class="main-wrapper"
				is-edit
				@onConfirm="submit"
				@onCancel="$router.push({ name: 'Users'})"
			/>
		</CForm>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, email, maxLength } from 'vuelidate/lib/validators';
import { validateHandler, checkIfValid } from '../assets/js/selectors/global/validation';
import { scrollToTop } from '../assets/js/helpers';

export default {
	validations: {
		username: {
			required,
			minLength: minLength(4), // false
			maxLength: maxLength(255),
		},
		password: {
			required(value) {
				if (this.isAllowChangePassword) {
					return required(value);
				}
				return true;
			},
			minLength: minLength(8),
			maxLength: maxLength(255),
		},
		firstName: {
			required,
			maxLength: maxLength(35),

		},
		lastName: {
			maxLength: maxLength(35),
		},
		email: {
			format(value) {
				return email(value);
			},
			maxLength: maxLength(255),
		},
		phone: {
			maxLength: maxLength(255),
		},
		note: {
			maxLength: maxLength(150),
		},
	},

	mixins: [validationMixin],

	data() {
		return {
			activeLabel: this.$t('page.users.create.value.status.inactive'),
			username: null,
			password: null,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			store: null,
			note: null,
			group: null,
			status: null,
			sumitCount: 0,
			isAllowChangePassword: false,
		};
	},
	computed: {
		...mapGetters({
			userInfo: 'users/userInfo',
			warehouseOptions: 'warehouses/warehouseOptions',
		}),
		...mapState('users', {
			edit: 'edit',
		}),

		fullName() {
			const firstName = this.userInfo.firstName;
			const lastName = this.userInfo.lastName;

			return `${firstName}${lastName ? ` ${lastName}` : ''}`;
		},
		isLoading() {
			return this.edit.isLoading;
		},
		isUpdating() {
			return this.edit.isUpdating;
		},
		isSuccess() {
			return this.edit.isSuccess;
		},
		fieldErrors() {
			return this.edit.fieldErrors;
		},
		isValidUsername() {
			const field = this.$v.username;
			return checkIfValid(field);
		},
		isValidPassword() {
			const field = this.$v.password;
			return checkIfValid(field);
		},
		isValidEmail() {
			const field = this.$v.email;
			return checkIfValid(field);
		},
		isValidPhone() {
			const field = this.$v.phone;
			return checkIfValid(field);
		},
		isValidFirstName() {
			const field = this.$v.firstName;
			return checkIfValid(field);
		},
		isValidLastName() {
			const field = this.$v.lastName;
			return checkIfValid(field);
		},
		isValidNote() {
			const field = this.$v.note;
			return checkIfValid(field);
		},
		getValidateUsernameMessage() {
			const field = this.$v.username;
			return validateHandler(field, {
				existMessage: this.$t('page.users.create.validationMessage.username.exists'),
				requiredMessage: this.$t('page.users.create.validationMessage.username.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.username.lengthLimit'),
			});
		},
		getValidatePasswordMessage() {
			const field = this.$v.password;

			return validateHandler(field, {
				requiredMessage: this.$t('page.users.create.validationMessage.password.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.password.lengthLimit'),
			});
		},
		getValidateEmailMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				existMessage: this.$t('page.users.create.validationMessage.email.exists'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.email.lengthLimit'),
				invalidFormatMessage: this.$t('page.users.create.validationMessage.email.invalidFormat'),
			});
		},
		getValidatePhoneMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.phone.lengthLimit'),
			});
		},
		getValidateFirstNameMessage() {
			const field = this.$v.firstName;

			return validateHandler(field, {
				requiredMessage: this.$t('page.users.create.validationMessage.firstName.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.firstName.lengthLimit'),
			});
		},
		getValidateLastNameMessage() {
			const field = this.$v.lastName;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.lastName.lengthLimit'),
			});
		},
		getValidateNoteMessage() {
			const field = this.$v.note;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.note.lengthLimit'),
			});
		},
		getPasswordDescription() {
			const field = this.$v.password;

			if (field.$error) {
				return '';
			}
			return this.$t('page.users.create.description.password');
		},
		formValid() {
			return !this.$v.$invalid;
		},
		getStatusLabel() {
			if (this.status) {
				return this.$t('page.users.create.value.status.active');
			}
			return this.$t('page.users.create.value.status.inactive');
		},
	},
	async created() {
		const userId = this.$route.params.id;

		await this.getUser(userId);
		await this.getWarehouseList({ per_page: 'all' });

		const storeValue = this.warehouseOptions.find((warehouse) => warehouse.value === this.userInfo.storeId);

		// Update Page title
		this.updatePageTitle();

		this.username = this.userInfo.username;
		this.email = this.userInfo.email;
		this.phone = this.userInfo.phoneNumber;
		this.password = this.userInfo.password;
		this.firstName = this.userInfo.firstName;
		this.lastName = this.userInfo.lastName;
		this.status = this.userInfo.status;
		this.store = storeValue || null;
		this.note = this.userInfo.note;
	},
	mounted() {
		scrollToTop();
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			getUser: 'users/getUser',
			updateUser: 'users/updateUser',
			clearError: 'users/clearError',
			showToast: 'toast/showToast',
			getWarehouseList: 'warehouses/getWarehouseList',
		}),

		async submit() {
			this.$v.$touch();
			if (this.formValid) {
				const userDataStore = {
					name: this.username,
					email: this.email,
					password: this.password,
					first_name: this.firstName,
					last_name: this.lastName,
					is_active: this.status,
					phone_number: this.phone,
					store_id: this.store?.value || null,
					group: this.group,
					note: this.note,
				};
				const userId = this.$route.params.id;

				await this.updateUser({
					userId,
					userDataStore,
				});

				if (this.isSuccess) {
					this.showToast({
						content: this.$t('global.successMessage'),
						header: this.$t('global.successMessageTitle'),
						type: 'success',
					});

					this.updatePageTitle();
				} else {
					let errorMessage = this.$t('global.errorMessage');

					if (this.fieldErrors.username) {
						errorMessage = this.fieldErrors.username;
					} else if (this.fieldErrors.email) {
						errorMessage = this.fieldErrors.email;
					}


					this.showToast({
						content: errorMessage,
						header: this.$t('global.errorMessageTitleEdit'),
						type: 'danger',
					});

					scrollToTop();
				}
			} else {
				scrollToTop();
			}
		},
		handleAllowChangePassword() {
			this.isAllowChangePassword = true;
		},
		handleSelectGroup(value) {
			this.clearError();
			this.group = value;
		},
		handleClickSwitch() {
			this.clearError();
		},
		updatePageTitle() {
			this.setPageTitle({
				title: this.fullName,
				isShow: true,
			});
		},
	},
};
</script>
<style lang="scss" scoped>
	.btn-cancel,
	.btn-create {
		min-width: rem(132);
	}

	.btn-cancel {
		margin-left: rem(34);
	}

	.btn-change-password {
		min-width: rem(155);
	}

	.wrapper-active-field {
		display: flex;
		align-items: center;

		.switch-active-label {
			margin-right: rem(40);
			margin-bottom: 0;
		}

		.swtich-active-status-button {
			margin-right: rem(12);
		}

		.label-active-status {
			margin-bottom: 0;
		}
	}
</style>
